import React, { useEffect, useCallback } from "react";
import { createPortal } from "react-dom";
import { ContainerModal, Button, SubContainer, ModalOverlay } from "./styles";
import "./styles.css";

interface ModalProps {
  content: React.ReactNode;
  onClose?: (e: any) => void;
  backgroundColor: string;
  closeColor: string;
  showModal?: boolean;
  width?: string;
  padding?: string;
}

const CustomModal = ({
  content,
  onClose,
  backgroundColor = "white",
  closeColor,
  showModal = false,
  width = "350px",
  padding = "20px",
}: ModalProps) => {
  const handleEscapeKey = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Escape" && onClose) {
        onClose(event);
      }
    },
    [onClose]
  );

  useEffect(() => {
    if (showModal) {
      document.addEventListener("keydown", handleEscapeKey);
      document.body.style.overflow = "hidden";
    }

    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
      document.body.style.overflow = "unset";
    };
  }, [showModal, handleEscapeKey]);

  if (!showModal) return null;

  const modal = (
    <ModalOverlay onClick={onClose}>
      <ContainerModal
        backgroundColor={backgroundColor}
        width={width}
        padding={padding}
        onClick={(e) => e.stopPropagation()}
      >
        <SubContainer>
          <Button
            color={closeColor}
            onClick={onClose}
            backgroundColor={backgroundColor}
          >
            &times;
          </Button>
        </SubContainer>
        {content}
      </ContainerModal>
    </ModalOverlay>
  );

  return createPortal(modal, document.body);
};

export default CustomModal;
