import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import { isTabletOrMobileSize } from "../../utils/queries";
import EOMImageCarousel from "../EOMImageCarousel";
import { Address as AddressImage } from "../../assets/images";
import EOMButton from "../EOMButton";
import theme from "../../utils/theme";
import AmenitiesIconList from "../AmenitiesIconList";
import {
  addBasketProduct,
  setIsNextGymSelected,
  setSelectedProduct,
} from "../../slices/appSlice";
import { Business } from "../../utils/types";
import { CHECKOUT, DASHBOARD_GYM_OWNERS } from "../../utils/routes";
import { filterByName, replaceUrlFormat } from "../../utils/utils";
import {
  CONFIRM,
  NEXT_GYM_SELECTED,
  NEXT_GYM_UPDATED,
  SELECT,
  SOLD_OUT,
  SOMETHING_WENT_WRONG,
  START_NOW,
  WE_COULDNT_PROCCESS_PAYMENT,
  YOUR_ARE_ABOUT_TO_SELECT,
  YOUR_GYM_FOR_THE_UPCOMING,
  YOU_CANT_REPEAT_GYM,
} from "../../utils/strings";
import CustomModal from "../CustomModal";
import StatusScreen from "../StatusScreen";
import { subscribeToNextGymCall } from "../../api/apiOperations";
import {
  Container,
  Title,
  DescriptionContainer,
  Description,
  PresentationBox,
  AmenitiesContainer,
  Address,
  SocialIconsContainer,
  SocialIcon,
  Price,
  BottomContainer,
  AddressIcon,
  AddressContainer,
  SocialIconRef,
} from "./styles";
import "./styles";

interface GymCardProps {
  gymCard: Business;
}
const GymCard = ({ gymCard }: GymCardProps) => {
  const [open, setOpen] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [showStatusModal, setShowStatusModal] = useState<boolean>(false);
  const [selectionSuccess, setSelectionSuccess] = useState<boolean>(false);
  const [selectionFailure, setSelectionFailure] = useState<boolean>(false);
  const [selectionFailureMesssage, setSelectionFailureMessage] =
    useState<string>("");

  const { gymOwnerLoggedIn, memberLoggedIn } = useSelector(
    (state: RootState) => state.auth
  );

  const { userCanDowngradeToBasic, membershipUpgraded, isNextGymSelected } =
    useSelector((state: RootState) => state.appData);

  const isTabletOrMobile = useMediaQuery({ query: isTabletOrMobileSize });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    businessId,
    name,
    description,
    gallery,
    amenities,
    address,
    socialLinks,
    products,
  } = gymCard;

  const basicMembershipProduct = filterByName(products, "basic");

  const notFailureTitle = selectionSuccess
    ? NEXT_GYM_UPDATED
    : NEXT_GYM_SELECTED;

  const notFailureDescription = selectionSuccess
    ? YOUR_GYM_FOR_THE_UPCOMING
    : YOUR_ARE_ABOUT_TO_SELECT;

  const isGymSoldOutGym =
    name === "CKO EDGEWATER" ||
    name === "AIRLAB Fitness" ||
    name === "CrossFit Wynwood";

  const handleOpenCard = () => {
    setOpen(!open);
  };

  const handleAddProduct = () => {
    dispatch(addBasketProduct(gymCard));
    dispatch(setSelectedProduct(basicMembershipProduct));
    navigate(gymOwnerLoggedIn ? DASHBOARD_GYM_OWNERS : CHECKOUT);
  };

  const handleSelect = async () => {
    setShowStatusModal(true);
  };

  const handleSelectNextGym = async () => {
    try {
      setLoading(true);
      const result = await subscribeToNextGymCall(
        basicMembershipProduct?.businessProductId
      );
      if (result === 200) {
        setLoading(false);
        setSelectionSuccess(true);
        setSelectionFailure(false);
      }
    } catch (error) {
      setLoading(false);
      setSelectionFailureMessage(
        error === 1 ? WE_COULDNT_PROCCESS_PAYMENT : YOU_CANT_REPEAT_GYM
      );
      setSelectionFailure(true);
      setSelectionSuccess(false);
      setShowStatusModal(true);
    } finally {
      setLoading(false); // Always reset the loading state
    }
  };

  const handleOpenStatusModal = () => {
    setSelectionFailure(false);
    setSelectionSuccess(false);
    setShowStatusModal(!showStatusModal);
  };

  return (
    <Container key={businessId}>
      {open ? (
        <PresentationBox>
          <EOMImageCarousel
            gallery={gallery}
            title={name}
            handleOpen={handleOpenCard}
          />
        </PresentationBox>
      ) : (
        <DescriptionContainer onClick={handleOpenCard}>
          <Title>{name}</Title>
          <Description>{description}</Description>
          <AmenitiesContainer>
            <AmenitiesIconList amenities={amenities} />
          </AmenitiesContainer>
          <AddressContainer>
            <AddressIcon src={AddressImage} />
            <Address
              href={`http://maps.google.com/?q=${address?.fullAddress}`}
              target="_blank"
              rel="noopener"
            >
              {address?.fullAddress}
            </Address>
          </AddressContainer>
          <SocialIconsContainer>
            {socialLinks?.map(({ name, socialLink }) => {
              return (
                name &&
                socialLink && (
                  <SocialIconRef
                    href={replaceUrlFormat(socialLink)}
                    target="_blank"
                    rel="noopener noreferrer"
                    key={name}
                  >
                    <SocialIcon
                      src={require(`../../assets/images/${name}.svg`)}
                    />
                  </SocialIconRef>
                )
              );
            })}
          </SocialIconsContainer>
          <BottomContainer>
            <Price>$ {basicMembershipProduct?.preTaxCost}</Price>
            {isGymSoldOutGym ? (
              <EOMButton
                title={SOLD_OUT}
                onPress={memberLoggedIn ? handleSelect : handleAddProduct}
                buttonStyle={{
                  backgroundColor: theme.templateColors.buttonDisabled,
                  borderRadius: 3,
                  width: 100,
                  height: 15,
                }}
                titleStyle={{
                  color: "black",
                  fontSize: isTabletOrMobile ? 12 : 13,
                  textAlign: "center",
                }}
                loading={loading}
                disabled
              />
            ) : (
              <EOMButton
                title={SELECT}
                onPress={memberLoggedIn ? handleSelect : handleAddProduct}
                buttonStyle={{
                  backgroundColor:
                    memberLoggedIn &&
                    !userCanDowngradeToBasic &&
                    isNextGymSelected &&
                    membershipUpgraded
                      ? theme.templateColors.buttonDisabled
                      : theme.templateColors.lightYellow,
                  borderRadius: 3,
                  width: 100,
                  height: 15,
                }}
                titleStyle={{
                  color: "black",
                  fontSize: isTabletOrMobile ? 12 : 13,
                  textAlign: "center",
                }}
                loading={loading}
                disabled={
                  memberLoggedIn &&
                  !userCanDowngradeToBasic &&
                  isNextGymSelected &&
                  membershipUpgraded
                }
              />
            )}
          </BottomContainer>
        </DescriptionContainer>
      )}
      <CustomModal
        showModal={showStatusModal}
        onClose={handleOpenStatusModal}
        content={
          <StatusScreen
            showIcon={false}
            title={
              selectionFailure ? selectionFailureMesssage : notFailureTitle
            }
            description={
              selectionFailure ? SOMETHING_WENT_WRONG : notFailureDescription
            }
            failure={selectionFailure}
            successButtonTile={CONFIRM}
            loading={loading}
            onClose={handleOpenStatusModal}
            showButton={selectionSuccess ? false : true}
            onButtonClick={handleSelectNextGym}
            useButtonEvent={selectionSuccess ? false : true}
          />
        }
        backgroundColor={theme.templateColors.lightDark}
        closeColor={theme.templateColors.lightYellow}
      />
    </Container>
  );
};

export default GymCard;
