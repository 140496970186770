import styled from "styled-components";

interface ContainerModalProps {
  backgroundColor?: string;
  width?: string;
  padding?: string;
}

interface ButtonProps {
  color?: string;
  backgroundColor?: string;
}

export const ContainerModal = styled.div<ContainerModalProps>`
  background-color: ${(props) => props.backgroundColor};
  padding: ${(props) => props.padding};
  border-radius: 7px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin: 450px;
  width: ${(props) => props.width};
  text-align: center;
  @media (max-width: 1224px) {
    width: 300px;
    margin: 10px;
  }
`;

export const Button = styled.button<ButtonProps>`
  color: ${(props) => props.color};
  background-color: ${(props) => props.backgroundColor};
  border: none;
  font-size: 30px;
  font-weight: 100;
  margin-right: 5px;
  cursor: pointer;
`;

export const SubContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;
