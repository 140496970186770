import React, { useState } from "react";

import ImageUploading, { ImageListType } from "react-images-uploading";
import { PiUploadSimple } from "react-icons/pi";
import { useMediaQuery } from "react-responsive";

import {
  CANCEL,
  CREATE_PROFILE_CARD,
  GYM_CARD_CREATED,
  MIME_TYPES,
  PICTURES_SELECTED,
  SAVE,
  SELECT_AMENITIES_AVAILABLE,
  SOMETHING_WENT_WRONG,
} from "../../utils/strings";
import AmenitiesFormList from "../AmenitiesFormList";
import { allAmenitiesArr } from "../../utils/arrays";
import EOMButton from "../EOMButton";
import {
  addDollarSign,
  removeDollarAndConvertToNumber,
} from "../../utils/utils";
import {
  createBusinessProfileCall,
  getSignedGalleryUrlCall,
} from "../../api/apiOperations";
import theme from "../../utils/theme";
import { isTabletOrMobileSize } from "../../utils/queries";
import CustomModal from "../CustomModal";
import StatusScreen from "../StatusScreen";
import {
  Container,
  Title,
  ImagesContainer,
  ImagesTitle,
  ImageBox,
  SubImagesContainer,
  Image,
  Close,
  ImageBoxSub,
  CustomizedTextField,
  Form,
  RightForm,
  LeftForm,
  AmenitiesContainer,
  SubContainerForm,
  ButtonsContainer,
  Types,
} from "./styles";

interface EditGymCardProps {
  close: () => void;
}
const EditGymCard = ({ close }: EditGymCardProps) => {
  const [images, setImages] = useState<ImageListType>([]);
  const [imagesTwo, setImagesTwo] = useState<ImageListType>([]);
  const [imagesThree, setImagesThree] = useState<ImageListType>([]);
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [membershipPrice, setMembershipPrice] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [instagramUrl, setInstagramUrl] = useState<string>("");
  const [facebookUrl, setFacebookUrl] = useState<string>("");
  const [linkedinUrl, setLinkedinUrl] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedAmenities, setSelectedAmenities] = useState<string[]>([]);
  const [showStatusModal, setShowStatusModal] = useState<boolean>(false);
  const [saveProfileError, setSaveProfileError] = useState<boolean>(false);

  const isTabletOrMobile = useMediaQuery({ query: isTabletOrMobileSize });

  const handleAmenityClick = (e: any, amenityName: string) => {
    e.preventDefault();
    setSelectedAmenities((prevSelected) =>
      prevSelected.includes(amenityName)
        ? prevSelected.filter((name) => name !== amenityName)
        : [...prevSelected, amenityName]
    );
  };

  const handleSaveButton = async () => {
    try {
      setLoading(true);
      const createProfileResult = await createBusinessProfileCall({
        name: name,
        description: description,
        amenities: selectedAmenities,
        socialLinks: [
          { name: "facebook", socialLink: facebookUrl },
          { name: "instagram", socialLink: instagramUrl },
          { name: "linkedin", socialLink: linkedinUrl },
        ],
        address: {
          fullAddress: address,
        },
        premiumProductPrice: removeDollarAndConvertToNumber(membershipPrice),
        gallery: [],
      });

      const signedGalleryResult = await getSignedGalleryUrlCall(
        images,
        imagesTwo,
        imagesThree
      );
      if (
        createProfileResult === "success" &&
        signedGalleryResult === "success"
      ) {
        setSaveProfileError(false);
        setLoading(false);
        setShowStatusModal(true);
      } else {
        setLoading(false);
        setShowStatusModal(true);
        setSaveProfileError(true);
      }
    } catch (error) {
      setLoading(false);
      setShowStatusModal(true);
      setSaveProfileError(true);
    }
  };

  const onImageChange = (imageList: ImageListType) => {
    setImages(imageList as never[]);
  };

  const onImageChangeTwo = (imageList: ImageListType) => {
    setImagesTwo(imageList as never[]);
  };

  const onImageChangeThree = (imageList: ImageListType) => {
    setImagesThree(imageList as never[]);
  };

  const handleOpenStatusModal = () => {
    setShowStatusModal(!showStatusModal);
    setSaveProfileError(!saveProfileError);
  };
  const handleAfterSuccessModal = () => {
    setShowStatusModal(!showStatusModal);
    setSaveProfileError(!saveProfileError);
    if (!saveProfileError) close();
  };

  return (
    <Container>
      <Title>{CREATE_PROFILE_CARD}</Title>
      <ImagesContainer>
        <ImagesTitle>{PICTURES_SELECTED}</ImagesTitle>
        <SubImagesContainer>
          <ImageUploading
            value={images}
            onChange={onImageChange}
            maxNumber={1}
            acceptType={["jpg", "png"]}
          >
            {({ imageList, onImageUpdate, onImageRemove, dragProps }) => (
              <ImageBox>
                <Close onClick={() => onImageRemove(0)}>X</Close>
                <PiUploadSimple
                  onClick={() => onImageUpdate(0)}
                  style={{
                    position: "absolute",
                    right: 50,
                    top: 7,
                    color: theme.colors.darkGrey,
                  }}
                  size={20}
                />
                <Types>{MIME_TYPES}</Types>
                <ImageBoxSub onClick={() => onImageUpdate(0)}>
                  {imageList?.map((image, index) => (
                    <Image
                      key={index}
                      {...dragProps}
                      src={image?.dataURL}
                      alt="image"
                    />
                  ))}
                </ImageBoxSub>
              </ImageBox>
            )}
          </ImageUploading>
          <ImageUploading
            value={imagesTwo}
            onChange={onImageChangeTwo}
            maxNumber={1}
            acceptType={["jpg", "png"]}
          >
            {({ imageList, onImageUpdate, onImageRemove, dragProps }) => (
              <ImageBox>
                <Close onClick={() => onImageRemove(0)}>X</Close>
                <PiUploadSimple
                  onClick={() => onImageUpdate(0)}
                  style={{
                    position: "absolute",
                    right: 50,
                    top: 7,
                    color: theme.colors.darkGrey,
                  }}
                  size={20}
                />
                <Types>{MIME_TYPES}</Types>
                <ImageBoxSub onClick={() => onImageUpdate(0)}>
                  {imageList?.map((image, index) => (
                    <Image
                      key={index}
                      {...dragProps}
                      src={image?.dataURL}
                      alt="image"
                    />
                  ))}
                </ImageBoxSub>
              </ImageBox>
            )}
          </ImageUploading>
          <ImageUploading
            value={imagesThree}
            onChange={onImageChangeThree}
            maxNumber={1}
            acceptType={["jpg", "png"]}
          >
            {({ imageList, onImageUpdate, onImageRemove, dragProps }) => (
              <ImageBox>
                <Close onClick={() => onImageRemove(0)}>X</Close>
                <PiUploadSimple
                  onClick={() => onImageUpdate(0)}
                  style={{
                    position: "absolute",
                    right: 50,
                    top: 7,
                    color: theme.colors.darkGrey,
                  }}
                  size={20}
                />
                <Types>{MIME_TYPES}</Types>
                <ImageBoxSub onClick={() => onImageUpdate(0)}>
                  {imageList?.map((image, index) => (
                    <Image
                      key={index}
                      {...dragProps}
                      src={image?.dataURL}
                      alt="image"
                    />
                  ))}
                </ImageBoxSub>
              </ImageBox>
            )}
          </ImageUploading>
        </SubImagesContainer>
      </ImagesContainer>
      <Form>
        <SubContainerForm>
          <LeftForm>
            <CustomizedTextField
              value={name}
              onChange={(e: any) => {
                e.preventDefault();
                setName(e.target.value);
              }}
              label="Gym Name"
              placeholder="Gym Name"
              InputLabelProps={{
                shrink: true,
              }}
              autoComplete="off"
            />
            <CustomizedTextField
              value={description}
              onChange={(e: any) => {
                e.preventDefault();

                setDescription(e.target.value);
              }}
              label="Description"
              placeholder="Max 165 characters"
              InputLabelProps={{
                shrink: true,
              }}
              FormHelperTextProps={{
                sx: {
                  color: "grey",
                  marginBottom: "-15px",
                },
              }}
              inputProps={{ maxLength: 165 }}
              helperText={`${description.length}/165`}
              multiline
              autoComplete="off"
            />
            <CustomizedTextField
              value={membershipPrice}
              onChange={(e: any) => {
                e.preventDefault();
                // Allow only numbers (including decimal points, if needed)
                const onlyNums = e.target.value.replace(/[^0-9]/g, "");
                setMembershipPrice(addDollarSign(onlyNums));
              }}
              label="Upgraded Membership price"
              placeholder="$ 00.00"
              InputLabelProps={{
                shrink: true,
              }}
              autoComplete="off"
            />
            <CustomizedTextField
              value={address}
              onChange={(e: any) => {
                e.preventDefault();
                setAddress(e.target.value);
              }}
              label="Address"
              placeholder="e.g: 545 N Park Ave, Winter Park, FL 32789, United States"
              InputLabelProps={{
                shrink: true,
              }}
              autoComplete="off"
            />
          </LeftForm>
          <RightForm>
            <CustomizedTextField
              value={instagramUrl}
              onChange={(e: any) => {
                e.preventDefault();
                setInstagramUrl(e.target.value);
              }}
              label="Instagram URL profile"
              placeholder="e.g: https://www.instagram.com/jackedrabbitgyms/"
              InputLabelProps={{
                shrink: true,
              }}
              autoComplete="off"
            />
            <CustomizedTextField
              value={facebookUrl}
              onChange={(e: any) => {
                e.preventDefault();
                setFacebookUrl(e.target.value);
              }}
              label="Facebook URL profile"
              placeholder="e.g: https://www.facebook.com/jackedrabbitgyms"
              InputLabelProps={{
                shrink: true,
              }}
              autoComplete="off"
            />
            <CustomizedTextField
              value={linkedinUrl}
              onChange={(e: any) => {
                e.preventDefault();
                setLinkedinUrl(e.target.value);
              }}
              label="LinkedIn URL profile"
              placeholder="e.g: https://www.linkedin.com/company/jackedrabbit/"
              InputLabelProps={{
                shrink: true,
              }}
              autoComplete="off"
            />
          </RightForm>
        </SubContainerForm>
        <AmenitiesContainer>
          <ImagesTitle>
            {SELECT_AMENITIES_AVAILABLE} :{" "}
            {selectedAmenities?.length > 1
              ? selectedAmenities?.length - 1
              : selectedAmenities?.length}
          </ImagesTitle>
          <AmenitiesFormList
            amenities={allAmenitiesArr}
            selectedAmenities={selectedAmenities}
            onAmenityClick={handleAmenityClick}
          />
        </AmenitiesContainer>
        <ButtonsContainer>
          <EOMButton
            title={CANCEL}
            onPress={close}
            outlined
            buttonStyle={{
              borderRadius: 3,
              width: isTabletOrMobile ? "40%" : "45%",
              height: 15,
              marginTop: 30,
              alignContent: "center",
              border: `1px solid ${theme.templateColors.lightYellow}`,
            }}
            titleStyle={{
              color: theme.colors.white,
              fontSize: 14,
              textAlign: "center",
            }}
          />
          <EOMButton
            title={SAVE}
            onPress={handleSaveButton}
            loading={loading}
            disabled={
              !images ||
              !imagesTwo ||
              !imagesThree ||
              !name ||
              !description ||
              !membershipPrice ||
              !address ||
              selectedAmenities.length < 1
            }
            buttonStyle={{
              borderRadius: 3,
              width: isTabletOrMobile ? "40%" : "45%",
              height: 15,
              marginTop: 30,
              alignContent: "center",
            }}
            titleStyle={{
              color: "black",
              fontSize: 14,
              textAlign: "center",
            }}
          />
        </ButtonsContainer>
      </Form>
      <CustomModal
        showModal={showStatusModal}
        onClose={handleOpenStatusModal}
        content={
          <StatusScreen
            title={saveProfileError ? SOMETHING_WENT_WRONG : GYM_CARD_CREATED}
            failure={saveProfileError}
            onClose={handleOpenStatusModal}
            onButtonClick={handleAfterSuccessModal}
            useButtonEvent
          />
        }
        backgroundColor={theme.templateColors.lightDark}
        closeColor={theme.templateColors.lightYellow}
      />
    </Container>
  );
};

export default EditGymCard;
