import styled from "styled-components";
import TextField from "@mui/material/TextField";
import { styled as MUIstyled } from "@mui/material/styles";

import theme from "../../../utils/theme";

const { colors, templateColors, fontSizes } = theme;

interface TextFieldProps {
  width?: string;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${templateColors.primaryBlack};
  width: 100%;
  justify-content: flex-end;
  height: auto;
`;

export const SubContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${templateColors.primaryBlack};
  width: 100%;
  @media (max-width: 1224px) {
    flex-direction: column;
    width: 90%;
  }
`;

export const LeftContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${templateColors.primaryBlack};
  width: 65%;
  @media (max-width: 1224px) {
    width: 90%;
  }
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.black};
  width: 90%;
  padding-left: 10%;
  padding-top: 8%;
  @media (max-width: 1224px) {
    padding-left: 0%;
    width: 100%;
    padding-top: 15%;
  }
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  background-color: ${colors.black};
  width: 35%;
  padding-top: 15.5%;
  @media (max-width: 1224px) {
    width: 100%;
    align-items: center;
  }
`;

export const Title = styled.span`
  font-size: ${fontSizes.mediumTitle};
  font-weight: 700;
  color: ${colors.white};

  @media (max-width: 1224px) {
    font-size: ${fontSizes.mediumTitleMobile};
  }
`;

export const Subtitle = styled.span`
  font-size: ${fontSizes.subtitle};
  padding-top: 30px;
  color: ${colors.white};
  @media (max-width: 1224px) {
    font-size: ${fontSizes.subtitleMobile};
  }
`;

export const LoginTitle = styled.span`
  font-size: ${fontSizes.subtitle};
  color: ${templateColors.lightYellow};
  cursor: pointer;
  margin-left: 4px;
  @media (max-width: 1224px) {
    font-size: ${fontSizes.subtitleMobile};
  }
`;

export const Description = styled.span`
  font-size: ${fontSizes.description};
  color: ${colors.red};
  margin-top: 4px;
  @media (max-width: 1224px) {
    font-size: ${fontSizes.descriptionMobile};
    margin-bottom: 7px;
  }
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 2%;
  padding-bottom: 6%;
  width: 100%;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  @media (max-width: 1224px) {
    flex-direction: column;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 48%;
  height: 30px;
  align-content: flex-end;
  @media (max-width: 1224px) {
    width: 100%;
    margin-bottom: 18%;
  }
`;

export const PaymentContainer = styled.div`
  margin-top: 0%;
  height: 400px;
  @media (max-width: 1224px) {
    height: auto;
  }
`;

export const TitleBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-self: flex-start;
  height: 100px;
  margin-bottom: 20px;
  @media (max-width: 1224px) {
  }
`;

export const CustomizedTextField = MUIstyled(TextField)<TextFieldProps>`
  background-color: ${colors.black};
  margin-top: 30px;
  width: ${(props: any) => props.width};

  & .MuiInputLabel-formControl {
    color: ${colors.white} !important;
  }

  & .MuiInput { 
    &::input {
      &::placeholder {
        color: ${colors.grey} !important;
      }
    }
  }
  & .MuiOutlinedInput-root {
    & fieldset>legend {
      padding-right: 20px;
    }
    &.Mui-focused fieldset {
      border: 1px solid ${templateColors.lightYellow};
    }
  }

  &:hover .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${templateColors.lightYellow};
  }

  & .MuiInputBase-root {
    color: ${colors.grey} !important;
  }
 
  & .MuiOutlinedInput-root MuiInputBase {
    color: ${colors.white} !important;
  }

  & .MuiFormLabel-root-bhkJJT {
    color: ${colors.white} !important;
    font-size: ${fontSizes.subtitle};
    padding-right: 5px;
  }

  & .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${templateColors.lightYellow};
    :hover {
      border: 1px solid ${colors.white} !important;
    }

    & .Mui-focused .MuiInputAdornment-root {
      border: 1px solid ${templateColors.lightYellow};
      
    }
  }
 
`;

export const FooterBox = styled.div`
  width: 100%;
  position: relative;
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 8%;
`;
