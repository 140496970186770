import React, { useState, useEffect } from "react";

import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { IconButton, InputAdornment } from "@mui/material";
import { useSelector } from "react-redux";

import NavbarContainer from "../../../components/NavbarContainer";
import FooterSection from "../FooterSection";
import {
  CONTACT_INFORMATION,
  CREATE_YOUR,
  EMAIL_ALREADY_IN_USE,
  ERROR_EMAIL_ALREADY_IN_USE,
  LOGIN,
  MUST_BE_SAME_AS,
  MUST_HAVE_SIX,
  NEXT,
  PAYMENT_DETAILS,
  SOMETHING_WENT_WRONG,
  WE_COULDNT_PROCCESS,
} from "../../../utils/strings";
import { isTabletOrMobileSize } from "../../../utils/queries";
import EOMButton from "../../../components/EOMButton";
import SummaryCard from "../../../components/SummaryCard";
import CustomModal from "../../../components/CustomModal";
import Login from "../../auth/Login";
import theme from "../../../utils/theme";
import { signUpFirebase } from "../../../auth/operations";
import { validatePassword } from "../../../utils/utils";
import Payment from "../Payment";
import StatusScreen from "../../../components/StatusScreen";
import {
  Container,
  LeftContainer,
  LeftContainerWrapper,
  RightContainer,
  SubContainer,
  Title,
  Subtitle,
  Description,
  LoginTitle,
  Form,
  CustomizedTextField,
  Row,
  Column,
  FooterBox,
  ButtonsContainer,
  PaymentContainer,
  TitleBox,
} from "./styles";

const Checkout = () => {
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [repeatPassword, setRepeatPassword] = useState<string>("");
  const [repeatPassError, setRepeatPassError] = useState<boolean>(false);
  const [validPassword, setValidPassword] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showPasswordTwo, sethowPasswordTwo] = useState<boolean>(false);
  const [showPaymentForm, setShowPaymentForm] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false);
  const [phoneError, setPhoneError] = useState<boolean>(false);
  const [showStatusModal, setShowStatusModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [signUpFirebaseError, setSignUpFirebaseError] =
    useState<boolean>(false);
  const [signUpFirebaseInvalidEmail, setSignUpFirebaseInvalidEmail] =
    useState<boolean>(false);

  const isTabletOrMobile = useMediaQuery({ query: isTabletOrMobileSize });
  const { userExist, membershipUpgraded } = useSelector(
    (state: RootState) => state.appData
  );

  const formFieldsAreInvalid =
    !firstName ||
    !lastName ||
    !email ||
    !phone ||
    !password ||
    !repeatPassword ||
    emailError ||
    phoneError;
  const showPaymentFormToUser =
    userExist || showPaymentForm || membershipUpgraded;

  const { pathname } = useLocation();

  const handleClickShowPassword = () =>
    setShowPassword((show: boolean) => !show);

  const handleClickShowPasswordTwo = () =>
    sethowPasswordTwo((show: boolean) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleOpenModal = () => {
    setShowModal(!showModal);
  };

  const handleOpenStatusModal = () => {
    setShowStatusModal(!showStatusModal);
  };

  const handleNextButton = async () => {
    if (!formFieldsAreInvalid) {
      setLoading(true);

      const newUserResult = await signUpFirebase(email, password, false, true, {
        firstName: firstName,
        lastName: lastName,
        phone: phone,
      });

      if (
        newUserResult === ERROR_EMAIL_ALREADY_IN_USE ||
        newUserResult !== "success"
      ) {
        setLoading(false);
        setSignUpFirebaseError(true);
        setSignUpFirebaseInvalidEmail(
          newUserResult === ERROR_EMAIL_ALREADY_IN_USE
        );
        setShowStatusModal(true);
      } else {
        setLoading(false);
        setShowPaymentForm(true);
      }
    }
  };

  useEffect(() => {
    // Automatically scrolls to top whenever pathname changes
    window.scrollTo(0, 0);
  }, [pathname, showPaymentForm]);

  useEffect(() => {
    if (membershipUpgraded) setShowPaymentForm(true);
  }, [membershipUpgraded]);

  return (
    <Container>
      <NavbarContainer dashboardScreen checkoutScreen />
      <SubContainer>
        <LeftContainerWrapper>
          <LeftContainer>
            <TitleBox>
              <Title>
                {showPaymentFormToUser ? PAYMENT_DETAILS : CONTACT_INFORMATION}
              </Title>
              {!showPaymentFormToUser && (
                <Subtitle>
                  {CREATE_YOUR}
                  <LoginTitle onClick={handleOpenModal}>{LOGIN}</LoginTitle>
                </Subtitle>
              )}
            </TitleBox>
            <Form>
              {showPaymentFormToUser || membershipUpgraded ? (
                <PaymentContainer>
                  <Payment />
                </PaymentContainer>
              ) : (
                <>
                  <Row>
                    <CustomizedTextField
                      value={firstName}
                      onChange={(e: any) => {
                        e.preventDefault();
                        setFirstName(e.target.value);
                      }}
                      label="First Name"
                      placeholder="First Name"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      width={isTabletOrMobile ? "100%" : "48%"}
                      autoComplete="off"
                    />
                    <CustomizedTextField
                      value={lastName}
                      onChange={(e: any) => {
                        e.preventDefault();
                        setLastName(e.target.value);
                      }}
                      label="Last Name"
                      placeholder="Last Name"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      width={isTabletOrMobile ? "100%" : "48%"}
                      autoComplete="off"
                    />
                  </Row>
                  <CustomizedTextField
                    value={email}
                    onChange={(e: any) => {
                      e.preventDefault();
                      setEmail(e.target.value);
                      if (e.target.validity.valid) {
                        setEmailError(false);
                      } else {
                        setEmailError(true);
                      }
                    }}
                    label="E-mail for subscription confirmation"
                    placeholder="Email"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type="email"
                    inputProps={{
                      pattern:
                        "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$",
                    }}
                    FormHelperTextProps={{
                      sx: {
                        color: "red",
                      },
                    }}
                    helperText={emailError ? "Invalid email format" : ""}
                    autoComplete="off"
                  />
                  <CustomizedTextField
                    value={phone}
                    onChange={(e: any) => {
                      e.preventDefault();
                      setPhone(e.target.value);
                      if (e.target.validity.valid) {
                        setPhoneError(false);
                      } else {
                        setPhoneError(true);
                      }
                    }}
                    label="Phone Number"
                    placeholder="Phone Number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      pattern: "^[0-9]+$",
                    }}
                    FormHelperTextProps={{
                      sx: {
                        color: "red",
                      },
                    }}
                    helperText={phoneError ? "Invalid phone number" : ""}
                    type="tel"
                    autoComplete="off"
                  />
                  <Row>
                    <Column>
                      <CustomizedTextField
                        value={password}
                        onChange={(e: any) => {
                          e.preventDefault();
                          setPassword(e.target.value);
                          setValidPassword(validatePassword(e.target.value));
                        }}
                        label="Choose Password"
                        placeholder="Password"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        type={!showPassword ? "password" : "text"}
                        width={"100%"}
                        error={password?.length > 1 && !validPassword}
                        autoComplete="new-password"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                                style={{ color: theme.colors.grey }}
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      {password?.length > 1 && !validPassword && (
                        <Description>{MUST_HAVE_SIX}</Description>
                      )}
                    </Column>
                    <Column>
                      <CustomizedTextField
                        value={repeatPassword}
                        onChange={(e: any) => {
                          e.preventDefault();
                          setRepeatPassword(e.target.value);
                          setRepeatPassError(e.target.value !== password);
                        }}
                        label="Repeat Password"
                        placeholder="Repeat Password"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        type={!showPasswordTwo ? "password" : "text"}
                        width={"100%"}
                        error={repeatPassError}
                        autoComplete="new-password"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPasswordTwo}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                                style={{ color: theme.colors.grey }}
                              >
                                {showPasswordTwo ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      {repeatPassError && (
                        <Description>{MUST_BE_SAME_AS}</Description>
                      )}
                    </Column>
                  </Row>
                </>
              )}
            </Form>
            {!showPaymentFormToUser && (
              <ButtonsContainer>
                <EOMButton
                  title={NEXT}
                  onPress={handleNextButton}
                  disabled={formFieldsAreInvalid}
                  loading={loading}
                  buttonStyle={{
                    borderRadius: 3,
                    width: isTabletOrMobile ? "20%" : "25%",
                    height: 15,
                    alignContent: "center",
                  }}
                  titleStyle={{
                    color: "black",
                    fontSize: 14,
                    textAlign: "center",
                    alignSelf: "center",
                  }}
                />
              </ButtonsContainer>
            )}
          </LeftContainer>
        </LeftContainerWrapper>
        <RightContainer>
          <SummaryCard />
        </RightContainer>
      </SubContainer>
      <FooterBox>
        <FooterSection />
      </FooterBox>
      <CustomModal
        showModal={showModal}
        onClose={handleOpenModal}
        content={<Login />}
        backgroundColor={theme.templateColors.lightDark}
        closeColor={theme.templateColors.lightYellow}
      />
      <CustomModal
        showModal={showStatusModal}
        onClose={handleOpenStatusModal}
        content={
          <StatusScreen
            title={SOMETHING_WENT_WRONG}
            description={
              signUpFirebaseInvalidEmail
                ? EMAIL_ALREADY_IN_USE
                : WE_COULDNT_PROCCESS
            }
            successButtonTile=""
            failure={signUpFirebaseError}
            showDescription
            onClose={handleOpenStatusModal}
          />
        }
        backgroundColor={theme.templateColors.lightDark}
        closeColor={theme.templateColors.lightYellow}
      />
    </Container>
  );
};

export default Checkout;
