import styled from "styled-components";
import theme from "../../utils/theme";

const { colors, templateColors } = theme;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${templateColors.grey};
  width: 375px;
  height: 100px;
  border-radius: 12px;
  @media (max-width: 1224px) {
    width: 100%;
    flex-direction: column;
    height: 100px;
  }
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: "flex-start";
  width: 70%;
  padding-left: 8%;
  padding-right: 6%;

  @media (max-width: 1224px) {
    padding-left: 8%;
    padding-right: 6%;
    padding-top: 5%;
    height: 60%;
    line-height: 30px;
    width: 90%;
  }
`;

export const RightContainer = styled.div`
  width: 30%;
  @media (max-width: 1224px) {
    height: 40%;
    width: 90%;
    display: flex;
    justify-content: center;
    padding-top: 6%;
  }
`;

export const Title = styled.span`
  color: ${colors.white};
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  @media (max-width: 1224px) {
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
  }
`;

export const Description = styled.span`
  color: ${templateColors.lightGrey};
  margin-top: 20px;
  font-size: 14px;
  line-height: 24px;
  @media (max-width: 1224px) {
    margin-top: 6px;
    font-size: 9px;
    line-height: 14px;
  }
`;

export const LogoStyle = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 20px;

  @media (max-width: 1224px) {
    width: 12px;
    height: 12px;
    margin-right: 5px;
  }
`;
