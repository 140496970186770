import styled from "styled-components";
import theme from "../../../utils/theme";

const { fontSizes, templateColors } = theme;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${templateColors.primaryBlack};
  height: 530px;
  width: 100%;
  position: relative;
  @media (max-width: 1224px) {
    height: 500px;
    max-width: 100%;
  }
`;

export const SubtitleBox = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  padding-left: 13%;
  @media (max-width: 1224px) {
    margin-bottom: 2%;
  }
`;

export const VideoDiv = styled.div`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
  z-index: 0;
  margin-bottom: 51%;
  pointer-events: "none";
  @media (max-width: 1224px) {
    margin-bottom: 100%;
  }
`;

export const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 94%;
  background-color: #000;
  z-index: 500;
  opacity: 0.3;
  margin-top: 12%;

  @media (max-width: 933px) {
    height: 130%;
    margin-bottom: 7%;
    margin-top: 0%;
  }
  @media (max-width: 1224) {
    height: 90%;
    margin-bottom: 20%;
    margin-top: 0%;
  }
`;

export const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
  z-index: 0;
`;

export const IntroContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 52%;
  position: absolute;
  bottom: 40px;
  align-items: center;
  font-family: "Inter";

  @media (max-width: 1224px) {
    width: 79%;
    top: 150px;
    align-items: flex-start;
  }
`;

export const Title = styled.span`
  color: white;
  align-self: "center";
  font-size: ${fontSizes.bigTitle};
  font-weight: 800;
  text-align: center;
  z-index: 550;
  @media (max-width: 1224px) {
    font-size: ${fontSizes.bigTitleMobile};
    margin-bottom: 5%;
    text-align: left;
    padding-left: 13%;
    line-height: 41px;
  }
`;

export const SubTitle = styled.span`
  color: white;
  align-self: "flex-start";
  text-align: center;
  font-size: 22px;
  font-size: ${fontSizes.title};
  line-height: 30px;
  margin-top: 10px;
  z-index: 550;
  @media (max-width: 1224px) {
    font-size: ${fontSizes.titleMobile};
    line-height: 22px;
    font-weight: 400;
  }
`;

export const SubTitleBullet = styled.span`
  color: white;
  align-self: "flex-start";
  text-align: left;
  font-size: 25px;
  font-size: ${fontSizes.title};
  line-height: 30px;
  z-index: 550;
  @media (max-width: 1224px) {
    font-size: 18px;
    line-height: 23px;
    font-weight: 400;
    align-self: "center";
    margin-top: 7px;
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  @media (max-width: 1224px) {
    margin-top: 4%;
  }
`;

export const Image = styled.img`
  @media (max-width: 1224px) {
    object-fit: contain;
    width: 100%;
  }
`;
