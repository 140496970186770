import React from "react";

import { useSelector } from "react-redux";

import { GYM_HOP_DESCRIPTION, GYM_HOP_TITLE } from "../../../utils/strings";
import GymCard from "../../../components/GymCard";
import { sortGymsByCustomOrder } from "../../../utils/utils";
import {
  Container,
  TopContainer,
  Title,
  Subtitle,
  BottomContainer,
} from "./styles";

const GymHopSection = () => {
  const { allBusiness } = useSelector((state: RootState) => state.appData);
  const customOrdererArr = sortGymsByCustomOrder(
    allBusiness,
    "SWEAT440 Midtown Miami"
  );

  return (
    <Container id="gym-hop">
      <TopContainer>
        <Title style={{ color: "white" }}>{GYM_HOP_TITLE}</Title>
        {/* <Subtitle style={{ color: "white" }}>{GYM_HOP_DESCRIPTION}</Subtitle> */}
      </TopContainer>
      <BottomContainer>
        {customOrdererArr?.map((gymCard: any) => (
          <GymCard key={gymCard?.businessId} gymCard={gymCard} />
        ))}
      </BottomContainer>
    </Container>
  );
};

export default GymHopSection;
